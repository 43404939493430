module.exports = [
      "Michael got a cake for his birthday.",
      "We have a lot of snow in January.",
      "Hurry up!",
      "Please wait outside of the apartment. I will be out soon.",
      "I went to the movies alone, because everyone was busy.",
      "The secret agents recorded his voice.",
      "Watch out! You almost stepped on broken glass.",
      "There was no ice cream in the freezer, so I ordered some online.",
      "My pocketwatch fell into the river last Friday.",
      "She has already returned the book to the library.",
      "Shall I help you with your luggage?",
      "I think that is a good environment for learning too.",
      "I usually work on weekdays.",
      "I love eating junk food.",
      "He was not hungry, yet he ate the cake anyway.",
      "She felt really bad after cheating on the test.",
      "How was the literature test?",
      "I would really like to visit Hawaii one day - he said.",
      "I did not receive a receipt after yesterdays grocery shopping.",
      "I did not have a spare tire with me yesterday.",
      "There were terrible conditions in town: the roads were impassable.",
      "The teacher asked us if we were ready for the test.",
      "I should really go to bed - it is almost 2 am.",
      "My mom tries to be cool by doing the same things I do.",
      "That diamond looks glorious.",
      "If you like strawberry jam and French fries - try combining the two, it is certainly not as bad as it sounds.",
      "I would rather be a lion than a house cat.",
      "I checked if she was OK.",
      "He told us a particularly exciting story about elephants..",
      "We need to rent a room for our vacation on time.",
      "I keep trying until I succeed.",
      "Eight seats were free on the bus.",
      "He said that his teacher was quite strict.",
      "I will get a chance for promotion next year.",
      "He always speaks loud and clear.",
      "I prefer digital clocks over mechanical ones.",
      "The slow brown sloth climbs on top of the tree.",
      "It was already getting dark by the time we got there.",
      "He just made the deadline last Thursday.",
      "A song can brighten up the mood of anyone.",
      "My hard drive lost all its memory due to a magnet.",
      "The manager kicked them out of the store.",
      "Cats are excellent pets because they are clean.",
      "I used to be very energetic in elementary school.",
      "This month has been amazing.",
      "She is an excellent artist.",
      "I accidentally slammed the window on my head last month.",
      "We watched the waves crashing on the shore - it was an astounding sight.",
      "Wow, how does that work?",
      "Rachel plays the piano.",
      "Can I get more information about the details?",
      "I plan on investing in an electric vehicle - it is great for the environment.",
      "Sometimes it is better to take a break and go back to what you were doing later when you are in a calm state of mind.",
      "The sky was remarkably clear yesterday - I could not see any clouds.",
      "I forgot to shut my windows yesterday.",
      "I want to buy a onesie, but I am not sure if it would fit me.",
      "He had to stop gambling - he ran out of money.",
      "He tried his best.",
      "I have never been to Africa, but I would love to visit it someday.",
      "The clock of my grandma is always 5 minutes ahead.",
      "This building is ancient.",
      "The book is on the table.",
      "Their fence was surprisingly tall.",
      "Malls are great places to shop - I can find everything I need in one place.",
      "By the time they got there, there was a huge line going around the block.",
      "The sea is a long way from there.",
      "You should consider donating to a charity.",
      "She did not have time to join us, unfortunately.",
]